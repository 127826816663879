import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Get from "../../Get";

function ArticulosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_UNIDADES_NEGOCIO = process.env.REACT_APP_URL_UNIDADES_NEGOCIO;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;

  const [codigo, setCodigo] = useState("");
  const [codigoSAT, setCodigoSAT] = useState("");
  const [nombre, setNombre] = useState("");
  const [modelo, setModelo] = useState("");
  const [piezasPorCaja, setPiezasPorCaja] = useState("");
  const [costoUSD, setCostoUSD] = useState("");
  const [costoCNY, setCostoCNY] = useState("");
  const [costoMX, setCostoMX] = useState("");
  const [venta, setVenta] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [EAN, setEAN] = useState("NA");
  const [SKU, setSKU] = useState("NA");
  const [ASIN, setASIN] = useState("NA");
  const [linkML, setLinkML] = useState("");
  const [linkShein, setLinkShein] = useState("");
  const [linkAmazon, setLinkAmazon] = useState("");
  const [linkCoppel, setLinkCoppel] = useState("");
  const [linkLiverpool, setLinkLiverpool] = useState("");
  const [linkWalmart, setLinkWalmart] = useState("");
  const [linkElektra, setLinkElektra] = useState("");

  const [imagenes, setImagenes] = useState("");
  const [especiales, setEspeciales] = useState("");
  const [cargadoML, setCargadoML] = useState("");
  const [cargadoShein, setCargadoShein] = useState("");
  const [cargadoAmazon, setCargadoAmazon] = useState("");
  const [cargadoCoppel, setCargadoCoppel] = useState("");
  const [cargadoWalmart, setCargadoWalmart] = useState("");
  const [cargadoLiverpool, setCargadoLiverpool] = useState("");
  const [cargadoElektra, setCargadoElektra] = useState("");

  const [largoProducto, setLargoProducto] = useState(0);
  const [anchoProducto, setAnchoProducto] = useState(0);
  const [altoProducto, setAltoProducto] = useState(0);
  const [pesoProducto, setPesoProducto] = useState(0);
  const [largoEmpaque, setLargoEmpaque] = useState(0);
  const [anchoEmpaque, setAnchoEmpaque] = useState(0);
  const [altoEmpaque, setAltoEmpaque] = useState(0);
  const [pesoEmpaque, setPesoEmpaque] = useState(0);
  const [stockMinimo, setStockMinimo] = useState(0);
  const [stockMaximo, setStockMaximo] = useState(0);
  const [indiceRotacion, setIndiceRotacion] = useState(0);
  const [lineas, setLineas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [unidadesNegocio, setUnidadesNegocio] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState("");
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedUnidadNegocio, setSelectedUnidadNegocio] = useState("");
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [lista1, setLista1] = useState(0);
  const [lista2, setLista2] = useState(0);
  const [lista3, setLista3] = useState(0);
  const [lista4, setLista4] = useState(0);

  const [validaBoton, setValidaBoton] = useState(true);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(12), (val, index) => index + year);

  useEffect(() => {
    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMarcas = res.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_UNIDADES_NEGOCIO, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allUnidadesNegocio = res.data;
        setUnidadesNegocio(allUnidadesNegocio);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveArticulos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_ARTICULOS,
          {
            codigo,
            codigoSAT,
            nombre,
            modelo,
            piezasPorCaja,
            costoUSD,
            costoCNY,
            costoMX,
            venta,
            observaciones,
            EAN,
            SKU,
            ASIN,
            ASINAntiguo: ASIN,
            imagenes,
            linkML,
            linkShein,
            linkAmazon,
            linkCoppel,
            linkWalmart,
            linkLiverpool,
            linkElektra,
            cargadoML,
            cargadoShein,
            cargadoAmazon,
            cargadoCoppel,
            cargadoWalmart,
            cargadoLiverpool,
            cargadoElektra,
            largoProducto,
            anchoProducto,
            altoProducto,
            pesoProducto,
            largoEmpaque,
            anchoEmpaque,
            altoEmpaque,
            pesoEmpaque,
            stockMinimo,
            stockMaximo,
            indiceRotacion,
            lineas: selectedLinea,
            marcas: selectedMarca,
            familias: selectedFamilia,
            unidadesNegocio: selectedUnidadNegocio,
            proveedores: selectedProveedor,
            especiales,
            lista1,
            lista2,
            lista3,
            lista4,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )

        .then((data) => {
          axios
            .post(
              URL_INVENTARIOS,
              {
                articulos: data.data._id,
                areas: AREAS_CEDIS,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )

            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Modelo",
                  detalle: `${nombre} ${codigo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
              setValidaBoton(true);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card container">
          <h3 align="center">Nuevo Modelo</h3>
          {/* <Get/> */}
          <Form onSubmit={saveArticulos}>
            <Row>
              <Col md={3}>
                <Label>Codigo de Origen</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Codigo SAT</Label>
                <Input
                  type="text"
                  placeholder="CodigoSAT"
                  value={codigoSAT}
                  required
                  onChange={(e) => {
                    setCodigoSAT(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>EAN</Label>
                <Input
                  type="text"
                  placeholder="EAN"
                  value={EAN}
                  required
                  onChange={(e) => {
                    setEAN(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>SKU</Label>
                <Input
                  type="text"
                  placeholder="SKU"
                  value={SKU}
                  required
                  onChange={(e) => {
                    setSKU(e.target.value);
                  }}
                />
              </Col>
              </Row>
              <Row>
              <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Variante</Label>
                <Input
                  type="text"
                  placeholder="modelo"
                  value={modelo}
                  required
                  onChange={(e) => {
                    setModelo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Piezas Por Caja</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Piezas Por Caja"
                  value={piezasPorCaja}
                  required
                  onChange={(e) => {
                    setPiezasPorCaja(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>ASIN</Label>
                <Input
                  type="text"
                  placeholder="ASIN"
                  value={ASIN}
                  required
                  onChange={(e) => {
                    setASIN(e.target.value);
                  }}
                />
              </Col>
</Row>
<Row>
              <Col md={3}>
                <Label>Costo CNY</Label>
                <Input
                  type="number"
                  placeholder="Costo CNY"
                  value={costoCNY}
                  required
                  onChange={(e) => {
                    setCostoCNY(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>FOB USD</Label>
                <Input
                  type="number"
                  placeholder="FOB USD"
                  value={costoUSD}
                  required
                  onChange={(e) => {
                    setCostoUSD(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Costo MX</Label>
                <Input
                  type="number"
                  placeholder="Costo MX"
                  value={costoMX}
                  required
                  onChange={(e) => {
                    setCostoMX(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Venta</Label>
                <Input
                  type="number"
                  placeholder="Venta"
                  value={venta}
                  required
                  onChange={(e) => {
                    setVenta(e.target.value);
                  }}
                />
              </Col>
</Row>
<Row>
              <Col md={3}>
                <Label>Link ML</Label>
                <Input
                  type="text"
                  placeholder="LinkML"
                  value={linkML}
                  required
                  onChange={(e) => {
                    setLinkML(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Amazon</Label>
                <Input
                  type="text"
                  placeholder="LinkAmazon"
                  value={linkAmazon}
                  required
                  onChange={(e) => {
                    setLinkAmazon(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Shein</Label>
                <Input
                  type="text"
                  placeholder="LinkShein"
                  value={linkShein}
                  required
                  onChange={(e) => {
                    setLinkShein(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Coppel</Label>
                <Input
                  type="text"
                  placeholder="LinkCoppel"
                  value={linkCoppel}
                  required
                  onChange={(e) => {
                    setLinkCoppel(e.target.value);
                  }}
                />
              </Col>
              </Row>

              <Row>
              <Col md={3}>
                <Label>Link Walmart</Label>
                <Input
                  type="text"
                  placeholder="LinkWalmart"
                  value={linkWalmart}
                  required
                  onChange={(e) => {
                    setLinkWalmart(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Liverpool</Label>
                <Input
                  type="text"
                  placeholder="LinkLiverpool"
                  value={linkLiverpool}
                  required
                  onChange={(e) => {
                    setLinkLiverpool(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Link Elektra</Label>
                <Input
                  type="text"
                  placeholder="LinkElektra"
                  value={linkElektra}
                  required
                  onChange={(e) => {
                    setLinkElektra(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label className="mr-sm-2">Imagenes</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={imagenes}
                  required
                  onChange={(e) => {
                    setImagenes(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            </Row>

            <Row>
              
            <Col md={3}>
                <Label className="mr-sm-2">Cargado ML</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoML}
                  required
                  onChange={(e) => {
                    setCargadoML(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Amazon</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoAmazon}
                  required
                  onChange={(e) => {
                    setCargadoAmazon(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Shein</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoShein}
                  required
                  onChange={(e) => {
                    setCargadoShein(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Coppel</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoCoppel}
                  required
                  onChange={(e) => {
                    setCargadoCoppel(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
              </Row>
              <Row>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Walmart</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoWalmart}
                  required
                  onChange={(e) => {
                    setCargadoWalmart(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Liverpool</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoLiverpool}
                  required
                  onChange={(e) => {
                    setCargadoLiverpool(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label className="mr-sm-2">Cargado Elektra</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={cargadoElektra}
                  required
                  onChange={(e) => {
                    setCargadoElektra(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </Col>
            </Row>

            <Row>

            <Col md={3}>
                <Label>Largo Producto</Label>
                <Input
                  type="number"
                  placeholder="LargoProducto"
                  value={largoProducto}
                  required
                  onChange={(e) => {
                    setLargoProducto(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Ancho Producto</Label>
                <Input
                  type="number"
                  placeholder="AnchoProducto"
                  value={anchoProducto}
                  required
                  onChange={(e) => {
                    setAnchoProducto(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Alto Producto</Label>
                <Input
                  type="number"
                  placeholder="AltoProducto"
                  value={altoProducto}
                  required
                  onChange={(e) => {
                    setAltoProducto(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Peso Producto</Label>
                <Input
                  type="number"
                  placeholder="PesoProducto"
                  value={pesoProducto}
                  required
                  onChange={(e) => {
                    setPesoProducto(e.target.value);
                  }}
                />
              </Col>

            </Row>

            <Row>

            <Col md={3}>
                <Label>Largo Empaque</Label>
                <Input
                  type="number"
                  placeholder="LargoEmpaque"
                  value={largoEmpaque}
                  required
                  onChange={(e) => {
                    setLargoEmpaque(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Ancho Empaque</Label>
                <Input
                  type="number"
                  placeholder="AnchoEmpaque"
                  value={anchoEmpaque}
                  required
                  onChange={(e) => {
                    setAnchoEmpaque(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Alto Empaque</Label>
                <Input
                  type="number"
                  placeholder="AltoEmpaque"
                  value={altoEmpaque}
                  required
                  onChange={(e) => {
                    setAltoEmpaque(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Peso Empaque</Label>
                <Input
                  type="number"
                  placeholder="PesoEmpaque"
                  value={pesoEmpaque}
                  required
                  onChange={(e) => {
                    setPesoEmpaque(e.target.value);
                  }}
                />
              </Col>

            </Row>

            <Row>

            <Col md={3}>
                <Label>Stock Minimo</Label>
                <Input
                  type="number"
                  placeholder="StockMinimo"
                  value={stockMinimo}
                  required
                  onChange={(e) => {
                    setStockMinimo(e.target.value);
                  }}
                />
              </Col>
            <Col md={3}>
                <Label>Stock Maximo</Label>
                <Input
                  type="number"
                  placeholder="StockMaximo"
                  value={stockMaximo}
                  required
                  onChange={(e) => {
                    setStockMaximo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Indice Rotacion</Label>
                <Input
                  type="number"
                  placeholder="indiceRotacion"
                  value={indiceRotacion}
                  required
                  onChange={(e) => {
                    setIndiceRotacion(e.target.value);
                  }}
                />
              </Col>
            </Row>




            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Linea</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedLinea}
                  required
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Linea</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Marca</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedMarca}
                  required
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Marca</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Familia</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedFamilia}
                  required
                  onChange={(e) => {
                    setSelectedFamilia(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Familia</option>
                  {familias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">UnidadNegocio</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedUnidadNegocio}
                  required
                  onChange={(e) => {
                    setSelectedUnidadNegocio(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una UnidadNegocio</option>
                  {unidadesNegocio
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Proveedor</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Especiales</Label>
                <Input
                  type="select"
                  placeholder="Especiales"
                  value={especiales}
                  required
                  onChange={(e) => {
                    setEspeciales(e.target.value);
                  }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
              </Col>

            </Row>
<br />
            <h4>Precios de Venta</h4>
            <Row>
            <Col md={3}>
                <Label>Lista 1</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista1}
                  required
                  onChange={(e) => {
                    setLista1(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 2</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista2}
                  required
                  onChange={(e) => {
                    setLista2(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 3</Label>
                <Input
                    type="number"
                    min="0"
                    step="any"
                  value={lista3}
                  required
                  onChange={(e) => {
                    setLista3(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Lista 4</Label>
                <Input
                     type="number"
                     min="0"
                     step="any"
                  value={lista4}
                  required
                  onChange={(e) => {
                    setLista4(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosCreate;
