import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesDisponibles() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_inventarios ?(
      <div className="container">
        <br />
        <br />
          <Row>
         
          <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/ListadoDisponible" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.disponible_vendedores ?(
            <Button href="/ListadoDisponibleVendedor" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Disponible Vendedor
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Disponible Vendedor
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_disponible ?(
            <Button href="/ListadoAcumuladoMesnual" className="botonesMenu" color="success">
              <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Acumulado Mensual
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tasks fa-7x"></i>
          <br />
          <br />
          Acumulado Mensual
        </Button> }
          </Col>
        
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesDisponibles;
