import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Switch from '@material-ui/core/Switch';
import moment from "moment";

function ListadoDisponible() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [selectedMarca, setSelectedMarca] = useState("");
  const [unidades, setUnidades] = useState([]);
  const [selectedUnidad, setSelectedUnidad] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const [idEditArticulo, setIdEditArticulo] = useState("");
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);
  //--- Pagination
  const [comments, setComments] = useState([]);
  const [comments2, setComments2] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [totalTotalInventario, setTotalTotalInventario] = useState(0);
  const [totalTotalCalidad, setTotalTotalCalidad] = useState(0);
  const [totalTotalOtros, setTotalTotalOtros] = useState(0);
  const [totalTotalOtrosPend, setTotalTotalOtrosPend] = useState(0);
  const [totalTotalDrop, setTotalTotalDrop] = useState(0);
  const [totalTotalDropPend, setTotalTotalDropPend] = useState(0);
  const [totalTotalPorRecibir, setTotalTotalPorRecibir] = useState(0);
  const [totalTotalPedido, setTotalTotalPedido] = useState(0);
  const [totalTotalPorTraspasar, setTotalTotalPorTraspasar] = useState(0);
  const [totalTotalDisponible, setTotalTotalDisponible] = useState(0);
  const [totalTotalDisponibleTotal, setTotalTotalDisponibleTotal] = useState(0);

  const [articulosNombre, setArticulosNombre] = useState("");
  const [desglocePedidos, setDesglocePedidos] = useState([]);
  const [modalDesglocePedidos, setModalDesglocePedidos] = useState(false);
  const toggleDesglocePedidos = () => setModalDesglocePedidos(!modalDesglocePedidos);

  const [cargando, setCargando] = useState(true);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  let compara = new Date()
  compara.setDate(compara.getDate()-7);
  let fechaCompara = moment(compara).format("YYYY-MM-DD");

  const ITEMS_PER_PAGE = 50;

  // const Toast = Swal.mixin({
  //   toast: true,
  //   position: "center",
  //   showConfirmButton: false,
  //   // timer: 5000,
  //   timerProgressBar: true,
  //   didOpen: (toast) => {
  //     toast.addEventListener("mouseenter", Swal.stopTimer);
  //     toast.addEventListener("mouseleave", Swal.resumeTimer);
  //   },
  // });

  // if (cargando) {
  //   Toast.fire({
  //     icon: "success",
  //     title: "Danos unos segundos....",
  //   });
  // }


  useEffect(() => {
    toggleProgreso()
    axios
      .get(`${URL_INVENTARIOS}Disponible`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        // let array = allDisponible.map((a)=>{
        //   if(a.inventario != 0 || a.pedido !=0 || a.calidad !=0 || a.disponible !=0){
        //   return a
        //   }
        // }).filter(function (el) {
        //   return el != null;
        // })
        setComments(allDisponible);
        setModalProgreso(false)

        // setCargando(false);
        // Toast.close();

        let unicosArt = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArticulo === value.idArticulo)
        );
        setArticulos(unicosArt);

        let unicosMar = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idMarca === value.idMarca)
        );
        setMarcas(unicosMar);

        let unicosUni = allDisponible.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idUnidad === value.idUnidad)
        );
        setUnidades(unicosUni);

      })
      .catch((err) => {
        console.log(err);
      });


  }, []);


  function PDFTabla() {
    let total_inventario = 0;
    let total_calidad = 0;
    let total_otros = 0;
    let total_drop = 0;
    let total_pendSurtirOMP = 0;
    let total_pendSurtirDR = 0;
    let total_pedido = 0;
    let total_traspaso = 0;
    let total_disponible = 0;
    let total_disponibleTotal = 0;

    const data = comments2
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.SKU.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.calidad.toString().includes(search) ||
          comment.otros.toString().includes(search) ||
          comment.drop.toString().includes(search) ||
          comment.pendSurtirOMP.toString().includes(search) ||
          comment.pendSurtirDR.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.disponibleTotal.toString().includes(search) ||
          comment.disponible.toString().includes(search)
      )
      .sort((a, b) => (a.SKU > b.SKU ? 1 : -1))
      .map((a) => {

          total_inventario = parseFloat(total_inventario) + parseFloat(a.inventario);
            total_calidad = parseFloat(total_calidad) + parseFloat(a.calidad);
              total_otros = parseFloat(total_otros) + parseFloat(a.otros);
              total_drop = parseFloat(total_drop) + parseFloat(a.drop);
                total_pendSurtirOMP = parseFloat(total_pendSurtirOMP) + parseFloat(a.pendSurtirOMP);
                total_pendSurtirDR = parseFloat(total_pendSurtirDR) + parseFloat(a.pendSurtirDR);
          total_pedido = parseFloat(total_pedido) + parseFloat(a.pedido);
          total_traspaso = parseFloat(total_traspaso) + parseFloat(a.traspaso);
          total_disponible = parseFloat(total_disponible) + parseFloat(a.disponible);
          total_disponibleTotal = parseFloat(total_disponibleTotal) + parseFloat(a.disponibleTotal);
          return [
            a.SKU,
            a.nombre,
            new Intl.NumberFormat("en-US").format(a.inventario),
            new Intl.NumberFormat("en-US").format(a.calidad),
            new Intl.NumberFormat("en-US").format(a.otros),
            new Intl.NumberFormat("en-US").format(a.drop),
            new Intl.NumberFormat("en-US").format(a.pendSurtirOMP),
            new Intl.NumberFormat("en-US").format(a.pendSurtirDR),
            new Intl.NumberFormat("en-US").format(a.inventario + a.calidad),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.traspaso),
            new Intl.NumberFormat("en-US").format(a.disponible),
            new Intl.NumberFormat("en-US").format(a.disponibleTotal),
          ];
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      head: [
        [
          "SKU",
          "Nombre",
          "Inventario",
          "Calidad",
          "Disp Otros MP",
          "Disp Drop",
          "Pend. Surt. OMP",
          "Pend. Surt. Drop",
          "Tot Inv",
          "Pedido",
          "Traspaso",
          "Disponible",
          "Disponible Total"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      footStyles: {
        fontSize: 12,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_inventario),
          new Intl.NumberFormat("en-US").format(total_calidad),
          new Intl.NumberFormat("en-US").format(total_otros),
          new Intl.NumberFormat("en-US").format(total_drop),
          new Intl.NumberFormat("en-US").format(total_pendSurtirOMP),
          new Intl.NumberFormat("en-US").format(total_pendSurtirDR),
          new Intl.NumberFormat("en-US").format(
            total_inventario + total_calidad
          ),
          new Intl.NumberFormat("en-US").format(total_pedido),
          new Intl.NumberFormat("en-US").format(total_traspaso),
          new Intl.NumberFormat("en-US").format(total_disponible),
          new Intl.NumberFormat("en-US").format(total_disponibleTotal),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Disponible.pdf`);
  }

  function excel() {
    const dataExcel = comments2
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.calidad.toString().includes(search) ||
          comment.otros.toString().includes(search) ||
          comment.drop.toString().includes(search) ||
          comment.pendSurtirOMP.toString().includes(search) ||
          comment.pendSurtirDR.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.disponibleTotal.toString().includes(search) ||
          comment.disponible.toString().includes(search)
      )
      .sort((a, b) => (a.SKU > b.SKU ? 1 : -1))
      .map((a) => {
        if(
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          (selectedMarca.length === 0 || selectedMarca.includes(a.idMarca)) &&
          (selectedUnidad.length === 0 || selectedUnidad.includes(a.idUnidad))
          )
          return {
            SKU: a.SKU,
            Nombre: a.nombre,
            Unidad: a.unidad,
            Marca: a.marca,
            Inventario: a.inventario,
            Calidad: a.calidad,
            Otros: a.otros,
            Drop: a.drop,
            PendSurtirOMP: a.pendSurtirOMP,
            PendSurtirDR: a.pendSurtirDR,
            TotalInv: a.totInv,
            PendRecibir: a.porRecibir,
            PendSurtir: a.pedido,
            // Pedido: a.pedido,
            Traspaso: a.traspaso,
            DisponibleEli: a.disponible,
            DisponibleTotal: a.disponibleTotal,
          };
      });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoDisponible";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoDisponible",
        sheetFilter: [
          "SKU",
          "Nombre",
          "Unidad",
          "Marca",
          "Inventario",
          "Calidad",
          "Otros",
          "Drop",
          "PendSurtirOMP",
          "PendSurtirDR",
          "TotalInv",
          "PendRecibir",
          "PendSurtir",
          // "Pedido",
          "Traspaso",
          "DisponibleEli",
          "DisponibleTotal",
        ],
        sheetHeader: [
          "SKU",
          "Nombre",
          "Unidad",
          "Marca",
          "Inventario",
          "Calidad",
          "Otros",
          "Drop",
          "PendSurtirOMP",
          "PendSurtirDR",
          "TotalInv",
          "PendRecibir",
          "PendSurtir",
          // "Pedido",
          "Traspaso",
          "DisponibleEli",
          "DisponibleTotal",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_inventario = 0;
    let total_calidad = 0;
    let total_otros = 0;
    let total_drop = 0;
    let total_pendSurtirOMP = 0;
    let total_pendSurtirDR = 0;
    let total_pedido = 0;
    let total_traspaso = 0;
    let total_disponible = 0;
    let total_disponibleTotal = 0;

    const data = comments2
      .filter(
        (comment) =>
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.calidad.toString().includes(search) ||
          comment.otros.toString().includes(search) ||
          comment.drop.toString().includes(search) ||
          comment.pendSurtirOMP.toString().includes(search) ||
          comment.pendSurtirDR.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.disponibleTotal.toString().includes(search) ||
          comment.disponible.toString().includes(search)
      )
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
          total_inventario = total_inventario + a.inventario;
          total_calidad = total_calidad + a.calidad;
          total_otros = total_otros + a.otros;
          total_drop = total_drop + a.drop;
          total_pendSurtirOMP = total_pendSurtirOMP + a.pendSurtirOMP;
          total_pendSurtirDR = total_pendSurtirDR + a.pendSurtirDR;
          total_pedido = total_pedido + a.pedido;
          total_traspaso = total_traspaso + a.traspaso;
          total_disponible = total_disponible + a.disponible;
          total_disponibleTotal = total_disponibleTotal + a.disponibleTotal;

          return [
            a.nombre,
            new Intl.NumberFormat("en-US").format(a.inventario),
            new Intl.NumberFormat("en-US").format(a.calidad),
            new Intl.NumberFormat("en-US").format(a.otros),
            new Intl.NumberFormat("en-US").format(a.drop),
            new Intl.NumberFormat("en-US").format(a.pendSurtirOMP),
            new Intl.NumberFormat("en-US").format(a.pendSurtirDR),
            new Intl.NumberFormat("en-US").format(a.inventario + a.calidad),
            new Intl.NumberFormat("en-US").format(a.pedido),
            new Intl.NumberFormat("en-US").format(a.traspaso),
            new Intl.NumberFormat("en-US").format(a.disponible),
            new Intl.NumberFormat("en-US").format(a.disponibleTotal),
          ];
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Inventario",
          "Calidad",
          "Disp Otros MP",
          "Disp Drop",
          "Pend Surt OMP",
          "Pend Surt DR",
          "Tot Inv",
          "Pedido",
          "Traspso",
          "Disponible",
          "DisponibleTotal"
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      footStyles: {
        fontSize: 12,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      foot: [
        [
          "Total",
          new Intl.NumberFormat("en-US").format(total_inventario),
          new Intl.NumberFormat("en-US").format(total_calidad),
          new Intl.NumberFormat("en-US").format(total_otros),
          new Intl.NumberFormat("en-US").format(total_drop),
          new Intl.NumberFormat("en-US").format(total_pendSurtirOMP),
          new Intl.NumberFormat("en-US").format(total_pendSurtirDR),
          new Intl.NumberFormat("en-US").format(
            total_inventario + total_calidad
          ),
          new Intl.NumberFormat("en-US").format(total_pedido),
          new Intl.NumberFormat("en-US").format(total_traspaso),
          new Intl.NumberFormat("en-US").format(total_disponible),
          new Intl.NumberFormat("en-US").format(total_disponibleTotal),
        ],
      ],
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Disponible",
          email: mailTo,
          fileName: "ListadoDisponible.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Disponibles.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "SKU", field: "SKU", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Unidad Negocio", field: "unidad", sortable: true },
    { name: "Inventario", field: "inventario", sortable: true },
    { name: "Calidad", field: "calidad", sortable: true },
    { name: "Disp Otros MP", field: "otros", sortable: true },
    { name: "Disp Drop", field: "drop", sortable: true },
    { name: "Pend. Surt. OMP", field: "pendSurtirOMP", sortable: true },
    { name: "Pend. Surt. DR", field: "pendSurtirDR", sortable: true },
    { name: "Total Inv", field: "totInv", sortable: true },
    { name: "Pendiente Recibir", field: "porRecibir", sortable: true },
    { name: "Pendiente Surtir", field: "pedido", sortable: true },
    { name: "Pendiente Traspaso", field: "traspaso", sortable: true },
    { name: "Disponible Eli", field: "disponible", sortable: true },
    { name: "Disponible Total", field: "disponibleTotal", sortable: true },
    { name: "Botones", field: "botones", sortable: false},

  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.SKU.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.unidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.inventario.toString().includes(search) ||
          comment.totInv.toString().includes(search) ||
          comment.calidad.toString().includes(search) ||
          comment.otros.toString().includes(search) ||
          comment.drop.toString().includes(search) ||
          comment.pendSurtirDR.toString().includes(search) ||
          comment.pendSurtirOMP.toString().includes(search) ||
          comment.pedido.toString().includes(search) ||
          comment.traspaso.toString().includes(search) ||
          comment.disponibleTotal.toString().includes(search) ||
          comment.disponible.toString().includes(search)
      );
    }

    console.log("hola", computedComments)
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }

    // if (selectedMarca) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idMarca.includes(selectedMarca)
    //   );
    // }

    // if (selectedUnidad) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idUnidad.includes(selectedUnidad)
    //   );
    // }
if(selectedUnidad.length > 0){
  if (selectedUnidad) {
    computedComments = computedComments.filter((e) =>
      selectedUnidad.some((a) => e.idUnidad && e.idUnidad.includes(a))
    );
  }
}


if(selectedMarca.length > 0){
  if (selectedMarca) {
    computedComments = computedComments.filter((e) =>
      selectedMarca.some((a) => e.idMarca && e.idMarca.includes(a))
    );
  }
}
    setTotalItems(computedComments.length);

    let inv = computedComments.map((c) => parseFloat(c.inventario));
    let Tinv = inv.reduce((t, inventario, index) => t + inventario, 0);
    setTotalTotalInventario(Tinv);

    let cal = computedComments.map((c) => parseFloat(c.calidad));
    let Tcal = cal.reduce((t, calidad, index) => t + calidad, 0);
    setTotalTotalCalidad(Tcal);

    let oth = computedComments.map((c) => parseFloat(c.otros));
    let Toth = oth.reduce((t, otros, index) => t + otros, 0);
    setTotalTotalOtros(Toth);

    let dr = computedComments.map((c) => parseFloat(c.drop));
    let TotDR = dr.reduce((t, drop, index) => t + drop, 0);
    setTotalTotalDrop(TotDR);

    let othPend = computedComments.map((c) => parseFloat(c.pendSurtirOMP));
    let TothPend = othPend.reduce((t, pendSurtirOMP, index) => t + pendSurtirOMP, 0);
    setTotalTotalOtrosPend(TothPend);

    let othdrop = computedComments.map((c) => parseFloat(c.pendSurtirDR));
    let TothDrop = othdrop.reduce((t, pendSurtirDR, index) => t + pendSurtirDR, 0);
    setTotalTotalDropPend(TothDrop);

    let rec = computedComments.map((c) => parseFloat(c.porRecibir));
    let Trec = rec.reduce((t, porRecibir, index) => t + porRecibir, 0);
    setTotalTotalPorRecibir(Trec);

    let ped = computedComments.map((c) => parseFloat(c.pedido));
    let Tped = ped.reduce((t, pedido, index) => t + pedido, 0);
    setTotalTotalPedido(Tped);

    let tras = computedComments.map((c) => parseFloat(c.traspaso));
    let Ttras = tras.reduce((t, traspaso, index) => t + traspaso, 0);
    setTotalTotalPorTraspasar(Ttras);

    let dis = computedComments.map((c) => parseFloat(c.disponible));
    let Tdis = dis.reduce((t, disponible, index) => t + disponible, 0);
    setTotalTotalDisponible(Tdis);

    let disTot = computedComments.map((c) => parseFloat(c.disponibleTotal));
    let TdisTot = disTot.reduce((t, disponibleTotal, index) => t + disponibleTotal, 0);
    setTotalTotalDisponibleTotal(TdisTot);

    setComments2(computedComments)

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "inventario" &&
      sorting.field != "totInv" &&
      sorting.field != "calidad" &&
      sorting.field != "otros" &&
      sorting.field != "drop" &&
      sorting.field != "pendSurtirOMP" &&
      sorting.field != "pendSurtirDR" &&
      sorting.field != "porRecibir" &&
      sorting.field != "pedido" &&
      sorting.field != "traspaso" &&
      sorting.field != "disponibleTotal" &&
      sorting.field != "disponible"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "inventario" ||
      sorting.field == "totInv" ||
      sorting.field == "calidad" ||
        sorting.field == "otros" ||
        sorting.field == "drop" ||
        sorting.field == "pendSurtirOMP" ||
        sorting.field == "pendSurtirDR" ||
        sorting.field == "porRecibir" ||
        sorting.field == "pedido" ||
        sorting.field == "traspaso" ||
        sorting.field == "disponibleTotal" ||
        sorting.field == "disponible")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "inventario" ||
      sorting.field == "totInv" ||
      sorting.field == "calidad" ||
        sorting.field == "otros" ||
        sorting.field == "drop" ||
        sorting.field == "pendSurtirOMP" ||
        sorting.field == "pendSurtirDR" ||
        sorting.field == "porRecibir" ||
        sorting.field == "pedido" ||
        sorting.field == "traspaso" ||
        sorting.field == "disponibleTotal" ||
        sorting.field == "disponible")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedArticulo,
    activo,
    selectedMarca,
    selectedUnidad,
  ]);


  function jalaFoto(idEdit) {
    setPhoto("");
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));

    setIdEditArticulo(idEdit);
    toggleFoto();
  }

  const handleCheckboxChangeUnidad = (e) => {
    const { value, checked } = e.target;
    // Actualiza el estado de los artículos seleccionados
    if (checked) {
      setSelectedUnidad([...selectedUnidad, value]);
    } else {
      setSelectedUnidad(selectedUnidad.filter(item => item !== value));
    }
  };

  const handleCheckboxChangeMarca = (e) => {
    const { value, checked } = e.target;
    // Actualiza el estado de los artículos seleccionados
    if (checked) {
      setSelectedMarca([...selectedMarca, value]);
    } else {
      setSelectedMarca(selectedMarca.filter(item => item !== value));
    }
  };


  function desglocePedido(
    idArticulo,
    SKU
  ) {
    setDesglocePedidos([])
    setArticulosNombre(SKU)

    axios
      .get(`${URL_ARTICULOS_PEDIDO}Pendiente/${idArticulo}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              cantidad: a.cantidad,
              pendiente_surtir: a.pendiente_surtir,
              pedido: a.pedidos[0].idPedido,
              cliente: a.pedidos[0].clientes[0].nombre_comercial,
              fecha: a.pedidos[0].fecha,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(allPedidos);
        setDesglocePedidos(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    toggleDesglocePedidos();
  }


  let totalDisponible = 0;
  let totalDisponibleTotal = 0;
  let totalPedido = 0;
  let totalTraspaso = 0;
  let totalInventario = 0;
  let totalCalidad = 0;
  let totalOtros = 0;
  let totalDrop = 0;
  let totalPendSurtirOMP = 0;
  let totalPendSurtirDR = 0;
  let totalPorRecibir = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_disponible ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Disponible</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Disponible en CEDIS</h3>


          <div className="row">
            <div className="col-md-2">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>

            <div className="col-md-2">
            <ul>
      <Label className="negrita" align="center">UNIDAD NEGOCIO</Label>
  {unidades.map((a) => (
    <li key={a.idUnidad}>
      <label>
        {/* <input
          type="checkbox"
          value={a.idUnidad}
          checked={selectedUnidad.includes(a.idUnidad)}
          onChange={(e) => handleCheckboxChangeUnidad(e, a.idUnidad)}
        /> */}
        <Switch
          value={a.idUnidad}
  checked={selectedUnidad.includes(a.idUnidad)}
  onChange={(e) => handleCheckboxChangeUnidad(e, a.idUnidad)}
  color="primary"
/>
        {a.unidad}
      </label>
    </li>
  ))}
</ul>
            </div>


            <div className="col-md-2">
            <ul>
            <Label className="negrita" align="center">MARCA</Label>
  {marcas.map((a) => (
    <li key={a.idMarca}>
      <label>
        {/* <input
          type="checkbox"
          value={a.idMarca}
          checked={selectedMarca.includes(a.idMarca)}
          onChange={(e) => handleCheckboxChangeMarca(e, a.idMarca)}
        /> */}
        <Switch
          value={a.idMarca}
          checked={selectedMarca.includes(a.idMarca)}
          onChange={(e) => handleCheckboxChangeMarca(e, a.idMarca)}
          color="primary"
/>
        {a.marca}
      </label>
    </li>
  ))}
</ul>
            </div>

           

            <div className="col-md-2"></div>


            <div className="col-md-2 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <br />
          <div className="table-wrapper">
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
            <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.SKU > b.SKU ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idArticulo}>{a.SKU}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a.idArticulo}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td> 
             
            </tr>
            <tbody>
              {commentsData.map((a) => {
                if(a.activo == "Si"){
                totalInventario = totalInventario + a.inventario;
                totalCalidad = totalCalidad + a.calidad;
                totalOtros = totalOtros + a.otros;
                totalDrop = totalDrop + a.drop;
                totalPendSurtirOMP = totalPendSurtirOMP + a.pendSurtirOMP;
                totalPendSurtirDR = totalPendSurtirDR + a.pendSurtirDR;
                totalPorRecibir = totalPorRecibir + a.porRecibir;
                totalPedido = totalPedido + a.pedido;
                totalTraspaso = totalTraspaso + a.traspaso;
                totalDisponible = totalDisponible + a.disponible;
                totalDisponibleTotal = totalDisponibleTotal + a.disponibleTotal;
                {
                  return (
                    
                    <tr>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6" }}>{a.SKU}</td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6" }}>{a.nombre}</td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}>{a.unidad}</td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}> {new Intl.NumberFormat("en-US").format(a.inventario)} </td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}> {new Intl.NumberFormat("en-US").format(a.calidad)} </td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}> {new Intl.NumberFormat("en-US").format(a.otros)} </td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}> {new Intl.NumberFormat("en-US").format(a.drop)} </td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}> {new Intl.NumberFormat("en-US").format(a.pendSurtirOMP)} </td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}> {new Intl.NumberFormat("en-US").format(a.pendSurtirDR)} </td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}> {new Intl.NumberFormat("en-US").format(a.totInv)} </td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}>{new Intl.NumberFormat("en-US").format(a.porRecibir)}</td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}>{new Intl.NumberFormat("en-US").format(a.pedido)}</td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}>{new Intl.NumberFormat("en-US").format(a.traspaso)}</td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}>{new Intl.NumberFormat("en-US").format(a.disponible)}</td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}>{new Intl.NumberFormat("en-US").format(a.disponibleTotal)}</td>
                      <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center" }}>
                        <>
                        {a.pedido > 0 ? (
                            <Button
                          color="success"
                          size="sm"
                          onClick={(e) =>
                            desglocePedido(
                              a.idArticulo,
                              a.SKU,
                            )
                          }
                        >
                          <i class="fas fa-shopping-basket"></i>
                        </Button>
                          ) : (
                        <Button
                          color="success"
                          size="sm"
                          disabled
                        >
                          <i class="fas fa-shopping-basket"></i>
                        </Button>
                          )}
                          <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) => jalaFoto(a.idArticulo)}
                          >
                            <i class="fas fa-camera"></i>
                          </Button>
                          </>
                      </td>
                    </tr>
                  );
                }
              }
              })}

              <tr>
                <td></td>
                <td></td>
                {/* <td></td>
                <td></td> */}
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  TOTAL GENERAL
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalInventario)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalCalidad)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalOtros)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalDrop)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalOtrosPend)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalDropPend)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalInventario + totalTotalCalidad + totalTotalOtros + totalTotalDrop + totalTotalDropPend + totalTotalOtrosPend)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalPorRecibir)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalPedido)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalPorTraspasar)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalDisponible)}
                </td>
                <td style={{ paddingTop: "0px", borderRight: "1px solid #dee2e6", textAlign: "center", fontWeight: "bold"}}>
                  {new Intl.NumberFormat("en-US").format(totalTotalDisponibleTotal)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          </div>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>Ficha Tecnica</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/arcoshopArticulos/${idEditArticulo}.jpg`}
                  alt=""
                />
              </h6>
            ) : (
              <h4>No se ha subido una Ficha Tecnica....</h4>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalDesglocePedidos} toggle={toggleDesglocePedidos}>
        <ModalHeader toggle={toggleDesglocePedidos}>
          <h4>Pedidos del Articulo: {articulosNombre}</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped bordered className="table-responsive-xl">
            <tr>
              <th>Pedido</th>
              <th>Fecha</th>
              <th>Cliente</th>
              <th>Cantidad Pedido</th>
              <th>Pendiente Surtir</th>
            </tr>
            <tbody>
              {desglocePedidos.map((a) => {
                return (
                  <tr
                  style={
                    a.fecha < fechaCompara
                      ? { backgroundColor: "#ed8c8c" }
                      : undefined
                      
                  }>
                    <td>{a.pedido}</td>
                    <td>{a.fecha}</td>
                    <td>{a.cliente}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    <td>{new Intl.NumberFormat("en-US").format(a.pendiente_surtir)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* </div> */}
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
    <ModalHeader toggle={toggleProgreso}>
      <h4>Progreso</h4>
    </ModalHeader>
    <ModalBody>
     <h4> Este proceso puede tardar varios segundos.
      <br />
      Por favor no cierre ni refresque su navegador.
      </h4>
      <br />
      <div className="progreso">
          <Spinner
            style={{
              width: "50px",
              height: "50px",
              color: "#232B41",
            }}
          />
          <Spinner
            style={{
              width: "50px",
              height: "50px",
              color: "#232B41",
            }}
          />
          <Spinner
            style={{
              width: "50px",
              height: "50px",
              color: "#232B41",
            }}
          />
      </div>
    </ModalBody>
  </Modal>
      {loader}
    </>
  );
}

export default ListadoDisponible;
