import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultipleSurtido from "../../StatusMultipleSurtido";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoPendienteSurtir() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");

  const [clientesName, setClientesName] = useState([]);
  const [clientesCodigo, setClientesCodigo] = useState([]);

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [numero_pedido, setNumeroPedido] = useState("");

  const [inventarios, setInventarios] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [totalCajas, setTotalCajas] = useState(0);

  const [selectedPedido, setSelectedPedido] = useState("");
  const [cliente, setCliente] = useState("");

  const [articulosPed, setArticulosPed] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [abrirSurtir, setAbrirSurtir] = useState(false);
  const [abrirSurtirQR, setAbrirSurtirQR] = useState(false);

  const [impuestos, setImpuestos] = useState("");
  const [comisionFlete, setComisionFlete] = useState(0);
  const [comisionTDC, setComisionTDC] = useState(0);

  const [idColaboradores, setIdColaboradores] = useState("");

  const [alertasVentasCliente, setAlertasVentasCliente] = useState("No");
  const [selectedClienteTelefono, setSelectedClienteTelefono] = useState("");
  const [vendedor, setVendedor] = useState("");
  const [area, setArea] = useState("");

  const [alias, setAlias] = useState("");
  const [calle, setCalle] = useState("");
  const [numero_ext, setNumero_ext] = useState("");
  const [numero_int, setNumero_int] = useState("");
  const [estado, setEstado] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [colonia, setColonia] = useState("");
  const [cp, setCp] = useState("");
  // const [paqueteria, setPaqueteria] = useState("");
  const [RFC, setRFC] = useState("");


  const [paqueterias, setPaqueterias] = useState([]);
  const [selectedPaqueteria, setSelectedPaqueteria] = useState("");

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      idArtPed: "",
      cajas: 0,
      piezasPorCaja: 0,
      precio: 0,
      total: 0,
      pendSurtir: 0,
      cantPed: 0,
      costoUnitario: 0,
    },
  ]);

  const [inputFields2, setInputFields2] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      idArtPed: "",
      cajas: 0,
      piezasPorCaja: 0,
      precio: 0,
      total: 0,
      pendSurtir: 0,
      cantPed: 0,
      costoUnitario: 0,
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    setSelectedArea(user.areas);
    axios
      .get(`${URL_PEDIDOS}PendienteSurtir`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPedidos = response.data;
        // Array para pagination
        let arrayTabla = allPedidos
          .sort((a, b) => (a.fechaModificado < b.fechaModificado ? 1 : -1))
          .map((a) => {
            if (user.areas == AREAS_GENERAL) {
              return {
                _id: a._id,
                numero: a.idPedido,
                activo: a.is_active,
                fecha: a.fecha,
                colaboradores:
                  a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
                idColaboradores: a.colaboradores[0]._id,
                observaciones: a.observaciones,
                total_general: a.total_general,
                clientes: a.clientes[0].nombre_comercial,
                codigo: a.clientes[0].codigo,
                idClientes: a.clientes[0]._id,
                total_cantidad: a.total_cantidad,
                status: a.status,
                autorizadoApartado: a.autorizadoApartado,
                autorizadoSurtido: a.autorizadoSurtido,
                idArea: a.areas[0]._id,
                area: a.areas[0].name,
                surtido: a.surtido,
                impuestos: a.impuestos,
                editado: a.editado,
                comisionFlete: a.comisionFlete,
                comisionTDC: a.comisionTDC,
                alias: a.sucursales[0].alias,
                calle: a.sucursales[0].calle,
                numero_ext: a.sucursales[0].numero_ext,
                numero_int: a.sucursales[0].numero_int,
                pais: a.sucursales[0].pais,
                estado: a.sucursales[0].estado,
                ciudad: a.sucursales[0].ciudad,
                delegacion: a.sucursales[0].delegacion,
                colonia: a.sucursales[0].colonia,
                cp: a.sucursales[0].cp,
                paqueteria: a.paqueterias[0].name,
                idPaqueteria: a.paqueterias[0]._id,
                RFC: a.sucursales[0].RFC,
                fechaModificado: a.fechaModificado,
                telefono: a.clientes[0].telefono,
                alertasVentas: a.clientes[0].alertasVentas,
              };
            } else if (user.areas == AREAS_CEDIS) {
              if (a.areas[0]._id == AREAS_CEDIS) {
                return {
                  _id: a._id,
                  numero: a.idPedido,
                  activo: a.is_active,
                  fecha: a.fecha,
                  colaboradores:
                    a.colaboradores[0].nombre +
                    " " +
                    a.colaboradores[0].apellido,
                  idColaboradores: a.colaboradores[0]._id,
                  observaciones: a.observaciones,
                  total_general: a.total_general,
                  clientes: a.clientes[0].nombre_comercial,
                  codigo: a.clientes[0].codigo,
                  idClientes: a.clientes[0]._id,
                  total_cantidad: a.total_cantidad,
                  status: a.status,
                  autorizadoApartado: a.autorizadoApartado,
                  autorizadoSurtido: a.autorizadoSurtido,
                  idArea: a.areas[0]._id,
                  area: a.areas[0].name,
                  surtido: a.surtido,
                  impuestos: a.impuestos,
                  editado: a.editado,
                  comisionFlete: a.comisionFlete,
                  comisionTDC: a.comisionTDC,
                  alias: a.sucursales[0].alias,
                  calle: a.sucursales[0].calle,
                  numero_ext: a.sucursales[0].numero_ext,
                  numero_int: a.sucursales[0].numero_int,
                  pais: a.sucursales[0].pais,
                  estado: a.sucursales[0].estado,
                  ciudad: a.sucursales[0].ciudad,
                  delegacion: a.sucursales[0].delegacion,
                  colonia: a.sucursales[0].colonia,
                  cp: a.sucursales[0].cp,
                  paqueteria: a.paqueterias[0].name,
                  idPaqueteria: a.paqueterias[0]._id,
                  RFC: a.sucursales[0].RFC,
                  fechaModificado: a.fechaModificado,
                  telefono: a.clientes[0].telefono,
                  alertasVentas: a.clientes[0].alertasVentas,
                };
              }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setComments(data);

        //
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_PAQUETERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPaqueterias = res.data;
        setPaqueterias(allPaqueterias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS}SurtirCEDIS`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Pedidos`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Vendedor: a.colaboradores,
          Cliente: a.clientes,
          Cantidad: a.total_cantidad,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoPedidos",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.colaboradores,
          a.clientes,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Vendedor",
          "Cliente",
          "Cantidad",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Pedidos",
          email: mailTo,
          fileName: "ListadoPedidos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Pedidos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    colaboradores,
    idClientes,
    clientes,
    codigo,
    total_cantidad,
    total_general,
    observaciones,
    numero,
    idArea,
    idPDFOC,
    alias,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    telefono
  ) {
    setSelectedArea(idArea);

    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir/${idPDFOC}/${idArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data.AP;
        let allInventarios = response.data.AI;

        let allInventarios2 = allInventarios
          .map((a) => {
            return {
              idArticulo: a.articulos[0]._id,
              articulo: a.articulos[0].SKU + " " + a.articulos[0].nombre,
              cantidadInventario: a.cantidad,
              lote: a.lote,
              almacen: a.areas[0].name,
              cantidadPedido: 0,
              surtido: 0,
              pendiente_surtir: 0,
              cajas: 0,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataInv = Object.values(allInventarios2);

        let agrupado = dataInv
          .sort((a, b) => (a.lote > b.lote ? 1 : -1))
          .reduce(function (groups, item) {
            const val = item["idArticulo"];
            groups[val] = groups[val] || {
              idArticulo: item.idArticulo,
              lote: "",
              cajas: 0,
            };
            groups[val].articulo = item.articulo;
            groups[val].cantidadPedido = item.cantidadPedido;
            groups[val].surtido = item.surtido;
            groups[val].pendiente_surtir = item.pendiente_surtir;
            groups[val].cajas = item.cajas;
            groups[val].piezasPorCaja = item.piezasPorCaja;
            groups[val].lote +=
              "[" + item.lote + " / " + item.cantidadInventario + "] - ";
            return groups;
          }, []);

        let dataAgrupado = Object.values(agrupado);

        let allArticulosPedido2 = allArticulosPedido
          .map((a) => {
            return {
              articulo: a.articulos[0].SKU + " " + a.articulos[0].nombre,
              idArticulo: a.articulos[0]._id,
              cantidadPedido: a.cantidad,
              surtido: a.surtido,
              pendiente_surtir: a.pendiente_surtir,
              lote: "",
              cajas:
                parseFloat(a.pendiente_surtir) /
                parseFloat(a.articulos[0].piezasPorCaja),
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataPedidos = Object.values(allArticulosPedido2);

        dataPedidos.map((a) =>
          dataAgrupado.map((b) => {
            if (a.idArticulo == b.idArticulo) {
              a.lote = b.lote;
            }
            return a;
          })
        );

        let totalPedido = dataPedidos.map((c) => parseFloat(c.cantidadPedido));
        let TP = totalPedido.reduce((t, total, index) => t + total, 0);

        let totalSurtido = dataPedidos.map((c) => parseFloat(c.surtido));
        let TS = totalSurtido.reduce((t, total, index) => t + total, 0);

        let totalPendSurtir = dataPedidos.map((c) =>
          parseFloat(c.pendiente_surtir)
        );
        let TPS = totalPendSurtir.reduce((t, total, index) => t + total, 0);

        let TC = dataPedidos.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        const data = dataPedidos
          .sort((a, b) => (a.articulo < b.articulo ? 1 : -1))
          .map((a) => {
            return [
              a.articulo,
              a.piezasPorCaja,
              new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
              new Intl.NumberFormat("en-US").format(a.pendiente_surtir),
              a.lote,
            ];
          })
          .sort((a, b) => (a.idArticulo > b.idArticulo ? 1 : -1));
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({ compressPdf: true });
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 5, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 11, 15, 15);

        doc.setFontSize(12);
        doc.text(`Fecha:`, 35, 15);
        doc.text(`${fecha}`, 60, 15);
        doc.text(`No. Pedido:`, 35, 20);
        doc.text(`${numero}`, 60, 20);

        doc.text(`Ejecutivo:`, 120, 15);
        doc.text(`${colaboradores}`, 150, 15);

        doc.setFontSize(8);
        doc.text(`Cliente: ${codigo} - ${clientes}`, 10, 32);
        doc.text(`RFC: ${RFC}`, 150, 32);
        doc.text(`Sucursal: ${alias}`, 10, 37);
        doc.text(
          `Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int} Telefono: ${telefono}`,
          10,
          42
        );
        doc.text(
          `Edo: ${estado}, Ciudad: ${ciudad}, Del: ${delegacion}, Col: ${colonia}, CP: ${cp}`,
          10,
          47
        );

        doc.autoTable({
          head: [["Articulo", "Pzs Caja", "Cajas", "Pend Surtir", "Lote"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
            // overflow: "linebreak",
            // columnWidth: "wrap",
            // // font: 'arial',
            // overflowColumns: "linebreak",
          },
          margin: { left: 5, right: 5 },
          startY: 50,
          footStyles: {
            fontSize: 9,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [
            [
              "",
              "Totales",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)),
              new Intl.NumberFormat("en-US").format(TPS),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);

        doc.save(`Pedido-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function excelOC(idArea, idPDFOC, clientes) {
    await axios
      .get(`${URL_ARTICULOS_PEDIDO}/surtir/${idPDFOC}/${idArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data.AP;
        let allInventarios = response.data.AI;

        let allInventarios2 = allInventarios
          .map((a) => {
            return {
              idArticulo: a.articulos[0]._id,
              articulo:
                a.articulos[0].nombre +
                " " +
                a.articulos[0].lineas[0].name +
                " " +
                a.articulos[0].familias[0].name,
              cantidadInventario: a.cantidad,
              lote: a.lote,
              almacen: a.areas[0].name,
              cantidadPedido: 0,
              surtido: 0,
              pendiente_surtir: 0,
              cajas: 0,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataInv = Object.values(allInventarios2);

        let agrupado = dataInv
          .sort((a, b) => (a.lote > b.lote ? 1 : -1))
          .reduce(function (groups, item) {
            const val = item["idArticulo"];
            groups[val] = groups[val] || {
              idArticulo: item.idArticulo,
              lote: "",
              cajas: 0,
            };
            groups[val].articulo = item.articulo;
            groups[val].cantidadPedido = item.cantidadPedido;
            groups[val].surtido = item.surtido;
            groups[val].pendiente_surtir = item.pendiente_surtir;
            groups[val].cajas = item.cajas;
            groups[val].piezasPorCaja = item.piezasPorCaja;
            groups[val].lote +=
              "[" + item.lote + " / " + item.cantidadInventario + "] - ";
            return groups;
          }, []);

        let dataAgrupado = Object.values(agrupado);

        let allArticulosPedido2 = allArticulosPedido
          .map((a) => {
            return {
              articulo:
                a.articulos[0].nombre +
                " " +
                a.articulos[0].lineas[0].name +
                " " +
                a.articulos[0].familias[0].name,
              idArticulo: a.articulos[0]._id,
              cantidadPedido: a.cantidad,
              surtido: a.surtido,
              pendiente_surtir: a.pendiente_surtir,
              lote: "",
              cajas:
                parseFloat(a.pendiente_surtir) /
                parseFloat(a.articulos[0].piezasPorCaja),
              piezasPorCaja: a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataPedidos = Object.values(allArticulosPedido2);

        dataPedidos.map((a) =>
          dataAgrupado.map((b) => {
            if (a.idArticulo == b.idArticulo) {
              a.lote = b.lote;
            }
            return a;
          })
        );

        const dataExcel = dataPedidos.map((a) => {
          return {
            Cliente: clientes,
            Articulo: a.articulo,
            PzsCaja: a.piezasPorCaja,
            Cajas: a.cajas,
            PendSurtir: a.pendiente_surtir,
            Lote: a.lote,
          };
        });
        const dataExportExcel = Object.values(dataExcel);
        const dataExcelLimpia = dataExportExcel.filter(function (el) {
          return el != null;
        });

        var option = {};

        option.fileName = "DesglocePedido";

        option.datas = [
          {
            sheetData: dataExcelLimpia,
            sheetName: "DesglocePedido",
            sheetFilter: [
              "Cliente",
              "Articulo",
              "PzsCaja",
              "Cajas",
              "PendSurtir",
              "Lote",
            ],
            sheetHeader: [
              "Cliente",
              "Articulo",
              "PzsCaja",
              "Cajas",
              "PendSurtir",
              "Lote",
            ],
          },
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Ultima Mod", field: "fechaModificacion", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "PDF", field: "pdf", sortable: false },
    { name: "Surtir", field: "surtir", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  // save
  const saveSurtidos = async (event) => {
    event.preventDefault();
    let cantidadIF = inputFields.length
    let contador = 0
      inputFields.map(async (a) => {
          if (a.cantInv >= a.cantidad) {
            return (contador = contador + 1);
          }
      });
      if(contador == cantidadIF){




    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;

    let iva = 0;
    let TT = 0;

    let STotal = inputFields.map((c) => parseFloat(c.total));
    let ST = STotal.reduce((t, total, index) => t + total, 0);

    if (impuestos != 0) {
      iva = (impuestos * ST) / 100;
    } else {
      iva = 0;
    }
    TT = (impuestos * ST) / 100 + ST + comisionFlete + comisionTDC;

    try {
      await axios
        .post(
          URL_SURTIDOS,
          {
            fecha,
            pedidos: selectedPedido,
            observaciones,
            user: user.id,
            clientes: cliente,
            areas: selectedArea,
            total_general: parseFloat(TT).toFixed(2),
            saldo: parseFloat(TT).toFixed(2),
            subTotal: parseFloat(ST).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos,
            is_active: "Si",
            colaboradores: idColaboradores,
            comisionPagada: "No",
            entregado: "No",
            paqueterias: selectedPaqueteria
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_SURTIDOS,
                  {
                    surtidos: data.data._id,
                    inventarios: a.idInv,
                    cantidad: parseFloat(a.cantidad),
                    articulos: a.articulos,
                    pedidos: selectedPedido,
                    articulosPedido: a.idArtPed,
                    areas: selectedArea,
                    clientes: cliente,
                    fecha,
                    costoUnitario: a.costoUnitario,
                    precio: a.precio,
                    paqueterias: selectedPaqueteria,
                    porEntregar: a.cantidad,
                    entregadoCantidad: 0,
                    entregado: "No"
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    if (alertasVentasCliente == "Si") {
                      PDFOCWA(
                        data.data.idSurtido,
                        parseFloat(ST).toFixed(2),
                        parseFloat(iva).toFixed(2),
                        parseFloat(TT).toFixed(2)
                      );
                    }
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Surtido",
                          detalle: `${numero_pedido} ${selectedArea} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                if (alertasVentasCliente == "Si") {
                  PDFOCWA(
                    data.data.idSurtido,
                    parseFloat(ST).toFixed(2),
                    parseFloat(iva).toFixed(2),
                    parseFloat(TT).toFixed(2)
                  );
                }
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Surtido",
                      detalle: `${numero_pedido} ${selectedArea} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }

  }else{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "No puedes vender sin inventario",
      showConfirmButton: false,
    });
  }

  };

  const saveSurtidosQR = async (event) => {
    event.preventDefault();
    let cantidadIF = inputFields2.length
    let contador = 0
      inputFields2.map(async (a) => {
          if (a.cantInv >= a.cantidad) {
            return (contador = contador + 1);
          }
      });
      if(contador == cantidadIF){




    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields2.length;

    let iva = 0;
    let TT = 0;

    let STotal = inputFields2.map((c) => parseFloat(c.total));
    let ST = STotal.reduce((t, total, index) => t + total, 0);

    if (impuestos != 0) {
      iva = (impuestos * ST) / 100;
    } else {
      iva = 0;
    }
    TT = (impuestos * ST) / 100 + ST + comisionFlete + comisionTDC;

    try {
      await axios
        .post(
          URL_SURTIDOS,
          {
            fecha,
            pedidos: selectedPedido,
            observaciones,
            user: user.id,
            clientes: cliente,
            areas: selectedArea,
            total_general: parseFloat(TT).toFixed(2),
            saldo: parseFloat(TT).toFixed(2),
            subTotal: parseFloat(ST).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos,
            is_active: "Si",
            colaboradores: idColaboradores,
            comisionPagada: "No",
            entregado: "No",
            paqueterias: selectedPaqueteria
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields2.map((a) => {
            if (a.cantidad > 0) {
              axios
                .post(
                  URL_ARTICULOS_SURTIDOS,
                  {
                    surtidos: data.data._id,
                    inventarios: a.idInv,
                    cantidad: parseFloat(a.cantidad),
                    articulos: a.articulos,
                    pedidos: selectedPedido,
                    articulosPedido: a.idArtPed,
                    areas: selectedArea,
                    clientes: cliente,
                    fecha,
                    costoUnitario: a.costoUnitario,
                    precio: a.precio,
                    paqueterias: selectedPaqueteria,
                    porEntregar: a.cantidad,
                    entregadoCantidad: 0,
                    entregado: "No"
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    if (alertasVentasCliente == "Si") {
                      PDFOCWA(
                        data.data.idSurtido,
                        parseFloat(ST).toFixed(2),
                        parseFloat(iva).toFixed(2),
                        parseFloat(TT).toFixed(2)
                      );
                    }
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Surtido",
                          detalle: `${numero_pedido} ${selectedArea} ${total}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            } else {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                if (alertasVentasCliente == "Si") {
                  PDFOCWA(
                    data.data.idSurtido,
                    parseFloat(ST).toFixed(2),
                    parseFloat(iva).toFixed(2),
                    parseFloat(TT).toFixed(2)
                  );
                }
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Surtido",
                      detalle: `${numero_pedido} ${selectedArea} ${total}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            // footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }

  }else{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "No puedes vender sin inventario",
      showConfirmButton: false,
    });
  }

  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        idArtPed: "",
        cajas: 0,
        piezasPorCaja: 0,
        precio: 0,
        total: 0,
        pendSurtir: 0,
        cantPed: 0,
        costoUnitario: 0,
      },
    ]);
  };

  const handleRemoveFields2 = (id) => {
    const values = [...inputFields2];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields2(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleAddFields2 = () => {
    const ultimo = inputFields2[inputFields2.length - 1];
    setInputFields2([
      ...inputFields2,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        idArtPed: "",
        cajas: 0,
        piezasPorCaja: 0,
        precio: 0,
        total: 0,
        pendSurtir: 0,
        cantPed: 0,
        costoUnitario: 0,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (
          (i.cantInv >= event.target.value &&
            i.pendSurtir >= event.target.value) ||
          event.target.value == ""
        ) {
          i[event.target.name] = event.target.value;
          i.cajas =
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
          i.total = parseFloat(event.target.value) * parseFloat(i.precio);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu pedido!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        if (
          (i.cantInv >= cantTemp && i.pendSurtir >= cantTemp) ||
          event.target.value == ""
        ) {
          i[event.target.name] = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          i.total =
            parseFloat(event.target.value) *
            parseFloat(i.piezasPorCaja) *
            parseFloat(i.precio);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede tu pedido!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inventarios.filter((e) => e.idArticulo == event.target.value).length == 1
    ) {
      let artPed = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.idArtPed;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let pendSurtir = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.pendSurtir;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let precio = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.precio;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario;
          handleChangeInputArticuloUnico(
            id,
            idArticulo,
            piezasPorCaja,
            cantInv,
            idInv,
            artPed,
            precio,
            pendSurtir,
            costoUnitario
          );
        }
      });
    } else {
      let artPed = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.idArtPed;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let pendSurtir = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.pendSurtir;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      let precio = articulosPed
        .map((a) => {
          if (a.articulos == event.target.value) {
            return a.precio;
          }
        })
        .filter(function (el) {
          return el != null;
        });

      inventarios.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario;
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            artPed,
            precio,
            pendSurtir,
            costoUnitario
          );
        }
      });
    }
  }
  const handleChangeInputArticuloUnico = (
    id,
    idArticulo,
    piezasPorCaja,
    cantInv,
    idInv,
    artPed,
    precio,
    pendSurtir,
    costoUnitario
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.idArtPed = artPed[0];
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.pendSurtir = parseFloat(pendSurtir);
        i.costoUnitario = costoUnitario;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    artPed,
    precio,
    pendSurtir
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.idArtPed = artPed[0];
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.pendSurtir = parseFloat(pendSurtir);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputArticuloQR = (
    id,
    idArticulo,
    piezasPorCaja,
    artPed,
    precio,
    costoUnitario,
    articuloNombre,
    cantPed,
    pendiente
  ) => {
    const newInputFields = inputFields2.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.articuloNombre = articuloNombre;
        i.idArtPed = artPed;
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.precio = parseFloat(precio);
        i.costoUnitario = parseFloat(costoUnitario);
        i.cantPed = parseFloat(cantPed);
        i.pendSurtir = parseFloat(pendiente);
      }
      return i;
    });
    setInputFields2(newInputFields);
    ActualizaTotales();
  };

  function BuscaLote(id, idInventario) {
      inventarios.map((a) => {
        if (a._id == idInventario) {
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let costoUnitario = a.costoUnitario;
          handleChangeInputArticuloLote(id, cantInv, idInv, costoUnitario);
        }
      });
  }

  function BuscaLoteQR(id, idInventario) {
      inventarios.map((a) => {
        if (a._id == idInventario) {
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let costoUnitario = a.costoUnitario;
          handleChangeInputArticuloLoteQR(id, cantInv, idInv, costoUnitario);
        }
      });
  }

  const handleChangeInputArticuloLoteQR = (id, cantInv, idInv, costoUnitario) => {
    const newInputFields = inputFields2.map((i) => {
      if (id === i.id) {
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.costoUnitario = costoUnitario;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputArticuloLote = (id, cantInv, idInv, costoUnitario) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.costoUnitario = costoUnitario;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);

    let cantCajas = inputFields.map((c) => parseFloat(c.cajas));
    let TTC = cantCajas.reduce((t, total, index) => t + total, 0);
    setTotalCajas(TTC);
  }

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value)) {
        let artPed = articulosPed
          .map((a) => {
            if (a.articulos == event.target.value) {
              return a.idArtPed;
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let precio = articulosPed
          .map((a) => {
            if (a.articulos[0]._id == event.target.value) {
              return a.precio;
            }
          })

          let cantidad = articulosPed
          .map((a) => {
            if (a.articulos[0]._id == event.target.value) {
              return a.cantidad;
            }
          })

          let pendSurt = articulosPed
          .map((a) => {
            if (a.articulos[0]._id == event.target.value) {
              return a.pendiente_surtir;
            }
          })
          let idArtPedQR = articulosPed
          .map((a) => {
            if (a.articulos[0]._id == event.target.value) {
              return a._id;
            }
          })
          .filter(function (el) {
            return el != null;
          });

        inventarios.map((a) => {
          if (a.idArticulo == event.target.value) {
            let idArticulo = a.idArticulo;
            let piezasPorCaja = a.piezasPorCaja;
            let costoUnitario = a.costoUnitario;
            let articuloNombre = a.SKU + " " + a.nombre;
            let precioArt = precio
            let cantPed = cantidad
            let pendiente = pendSurt
            let idArtPedido = idArtPedQR[0]
            handleChangeInputArticuloQR(
              id,
              idArticulo,
              piezasPorCaja,
              idArtPedido,
              precioArt,
              costoUnitario,
              articuloNombre,
              cantPed,
              pendiente
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en tu pedido",
          showConfirmButton: false,
        });
        const newInputFields = inputFields2.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields2(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields2(newInputFields);
    }
  }

  async function seleccionarPedido(
    id,
    numero,
    idClientes,
    clientes,
    codigo,
    idArea,
    impuestos,
    comisionFlete,
    comisionTDC,
    idColaboradores,
    alertasVentas,
    telefono,
    vendedor,
    area,
    alias,
    calle,
    numero_ext,
    numero_int,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    paqueteria,
    RFC
  ) {
    setSelectedPedido(id);
    setNumeroPedido(numero);
    setCliente(idClientes);
    setSelectedArea(idArea);
    setImpuestos(impuestos);
    setComisionFlete(comisionFlete);
    setComisionTDC(comisionTDC);
    setClientesName(clientes);
    setClientesCodigo(codigo);
    setIdColaboradores(idColaboradores);
    setAlertasVentasCliente(alertasVentas);
    setSelectedClienteTelefono(telefono);
    setVendedor(vendedor);
    setArea(area);
    setAlias(alias);
    setCalle(calle);
    setNumero_ext(numero_ext);
    setNumero_int(numero_int);
    setEstado(estado);
    setCiudad(ciudad);
    setDelegacion(delegacion);
    setColonia(colonia);
    setCp(cp);
    setSelectedPaqueteria(paqueteria);
    setRFC(RFC);
    setInputFields([])
    setInputFields2([])

    // if (idArea == AREAS_CEDIS) {
      axios
        .get(`${URL_ARTICULOS_PEDIDO}/surtir/${id}/${idArea}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allArticulosPed = res.data.AP;
          setArticulosPed(allArticulosPed);

          let arrayInputFields = allArticulosPed
            .map((a) => {
              return {
                id: uuidv4(),
                articulos: a.articulos[0]._id,
                cantidad: a.pendiente_surtir,
                // cantInv: a.cantInv,
                cantInv: 0,
                idInv: "",
                idArtPed: a._id,
                cajas: a.pendiente_surtir / a.articulos[0].piezasPorCaja,
                piezasPorCaja:a.articulos[0].piezasPorCaja,
                precio: a.precio,
                total: 0,
                pendSurtir: a.pendiente_surtir,
                articuloNombre: a.articulos[0].SKU + " " + a.articulos[0].nombre,
                cantPed: a.cantidad,
                costoUnitario: 0,
              };
            })
            .sort((a, b) => (a.articuloNombre > b.articuloNombre ? 1 : -1));

          let dataIF = Object.values(arrayInputFields);

          setInputFields(dataIF);

          let result = allArticulosPed.map((a) => a.articulos);
          setIdsArticulos(result);
        })
        .catch((err) => {
          console.log(err);
        });
    // }
    setAbrirSurtir(true);
  }


  async function seleccionarPedidoQR(
    id,
    numero,
    idClientes,
    clientes,
    codigo,
    idArea,
    impuestos,
    comisionFlete,
    comisionTDC,
    idColaboradores,
    alertasVentas,
    telefono,
    vendedor,
    area,
    alias,
    calle,
    numero_ext,
    numero_int,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    paqueteria,
    RFC
  ) {
    setSelectedPedido(id);
    setNumeroPedido(numero);
    setCliente(idClientes);
    setSelectedArea(idArea);
    setImpuestos(impuestos);
    setComisionFlete(comisionFlete);
    setComisionTDC(comisionTDC);
    setClientesName(clientes);
    setClientesCodigo(codigo);
    setIdColaboradores(idColaboradores);
    setAlertasVentasCliente(alertasVentas);
    setSelectedClienteTelefono(telefono);
    setVendedor(vendedor);
    setArea(area);
    setAlias(alias);
    setCalle(calle);
    setNumero_ext(numero_ext);
    setNumero_int(numero_int);
    setEstado(estado);
    setCiudad(ciudad);
    setDelegacion(delegacion);
    setColonia(colonia);
    setCp(cp);
    setSelectedPaqueteria(paqueteria);
    setRFC(RFC);
    setInputFields([])
    setInputFields2([
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        idArtPed: "",
        cajas: 0,
        piezasPorCaja: 0,
        precio: 0,
        total: 0,
        pendSurtir: 0,
        cantPed: 0,
        costoUnitario: 0,
      },
    ])

    // if (idArea == AREAS_CEDIS) {
      axios
        .get(`${URL_ARTICULOS_PEDIDO}/surtir/${id}/${idArea}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allArticulosPed = res.data.AP;
          setArticulosPed(allArticulosPed);

          let arrayInputFields = allArticulosPed
            .map((a) => {
              return {
                id: uuidv4(),
                articulos: a.articulos[0]._id,
                cantidad: a.pendiente_surtir,
                // cantInv: a.cantInv,
                cantInv: 0,
                idInv: "",
                idArtPed: a._id,
                cajas: a.pendiente_surtir / a.articulos[0].piezasPorCaja,
                piezasPorCaja:a.articulos[0].piezasPorCaja,
                precio: a.precio,
                total: 0,
                pendSurtir: a.pendiente_surtir,
                articuloNombre: a.articulos[0].SKU + " " + a.articulos[0].nombre,
                cantPed: a.cantidad,
                costoUnitario: 0,
              };
            })
            .sort((a, b) => (a.articuloNombre > b.articuloNombre ? 1 : -1));

          let dataIF = Object.values(arrayInputFields);

          setInputFields(dataIF);

          let result = allArticulosPed.map((a) => a.articulos[0]._id);
          setIdsArticulos(result);
        })
        .catch((err) => {
          console.log(err);
        });
    // }
    setAbrirSurtirQR(true);
  }

  function PDFOCWA(idSurtido, subTotal, iva, total) {
    const data = inputFields.map((a) => {
      return [
        a.articuloNombre,
        new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
        new Intl.NumberFormat("en-US").format(a.cantidad),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.precio),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.total),
      ];
    });

    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({ compressPdf: true });
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 8, 5, 18, 5);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 10, 11, 20, 5);

    doc.setFontSize(12);
    doc.text(`Fecha:`, 35, 15);
    doc.text(`${fecha}`, 60, 15);
    doc.text(`Nota:`, 35, 20);
    doc.text(`${idSurtido}`, 60, 20);
    doc.text(`Pedido:`, 35, 25);
    doc.text(`${numero_pedido}`, 60, 25);

    doc.text(`Ejecutivo:`, 120, 15);
    doc.text(`${vendedor}`, 150, 15);
    doc.text(`Almacen:`, 120, 20);
    doc.text(`${area}`, 150, 20);

    doc.setFontSize(8);
    doc.text(`Cliente: ${clientesCodigo} - ${clientesName}`, 10, 32);
    doc.text(`RFC: ${RFC}`, 150, 32);
    doc.text(`Sucursal: ${alias}`, 10, 37);
    doc.text(
      `Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int} Telefono: ${selectedClienteTelefono}`,
      10,
      42
    );
    doc.text(
      `Edo: ${estado}, Ciudad: ${ciudad}, Del: ${delegacion}, Col: ${colonia}, CP: ${cp}`,
      10,
      47
    );

    doc.autoTable({
      head: [["Articulo", "Cajas", "Cantidad", "Precio", "Total"]],
      body: dataPDFLimpia,
      styles: {
        fontSize: 8,
        // overflow: "linebreak",
        // columnWidth: "wrap",
        // // font: 'arial',
        // overflowColumns: "linebreak",
      },
      margin: { left: 5, right: 5 },
      startY: 50,
      footStyles: {
            fontSize: 9,
            fillColor: [255,255,255],
            textColor: [0,0,0]
          },
          foot: [
        [
          "",
          new Intl.NumberFormat("en-US").format(totalCajas.toFixed(2)) +
            " Cajas",
          new Intl.NumberFormat("en-US").format(total) + " Piezas",
          "Sub Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(subTotal),
        ],
        [
          "",
          "",
          "",
          `Comision`,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(iva),
        ],
        [
          "",
          "",
          "",
          "Comision TDC",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(comisionTDC),
        ],
        [
          "",
          "",
          "",
          "Comision Flete",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(comisionFlete),
        ],
        [
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(total),
        ],
      ],
      showFoot: "lastPage",
    });

    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // axios
    // .post(
    //   `${URL_WHATSAPP}SendPDF`,
    //   {
    //     number: selectedClienteTelefono,
    //     message:`Hola ${clientesName}, Estamos preparando tu pedido. Gracias por confiar en ${process.env.REACT_APP_NOMBRE_INSTITUCION}, estamos para servirte!`,
    //     base64File,
    //     fileName:`Nota-${idSurtido}.pdf`
    //   },
    //   {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem(
    //         "app_token"
    //       )}`,
    //     },
    //   }
    // )
  }
  return (
    <>
      <Header />
      <br />
      <br />
      {user.surtidos_create ? (
        <div className="card col-12">
          {!abrirSurtir && !abrirSurtirQR ? (
            <div>
              <Row>
                <Col md={8}>
                  <Button
                    size="sm"
                    href="/MenuVentas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado Pendiente Surtir</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Pendientes de Surtir</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>

              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Clientes</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedCliente}
                    onChange={(e) => {
                      setSelectedCliente(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {clientes
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Vendedor</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona un Vendedor</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                    if (a.editado == "Si") {
                      return (
                        <tr style={{ backgroundColor: "orange" }}>
                          <td>{a.numero}</td>
                          <td>{a.fechaModificado}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.area}</td>
                          <td>
                            {user.surtidos_create ? (
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                      a.colaboradores,
                                      a.idClientes,
                                      a.clientes,
                                      a.codigo,
                                      a.total_cantidad,
                                      a.total_general,
                                      a.observaciones,
                                      a.numero,
                                      a.idArea,
                                      a._id,
                                      a.alias,
                                      a.calle,
                                      a.numero_ext,
                                      a.numero_int,
                                      a.pais,
                                      a.estado,
                                      a.ciudad,
                                      a.delegacion,
                                      a.colonia,
                                      a.cp,
                                      a.RFC,
                                      a.paqueteria,
                                      a.area,
                                      a.telefono
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>

                                <Button
                                  size="sm"
                                  className="btn"
                                  color="primary"
                                  onClick={(e) =>
                                    excelOC(a.idArea, a._id, a.clientes)
                                  }
                                >
                                  <i class="far fa-file-excel"></i>
                                </Button>
                              </div>
                            ) : undefined}
                          </td>
                          {a.surtido == "No" ? (
                            <td>
                            <>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarPedido(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.codigo,
                                  a.idArea,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a.idColaboradores,
                                  a.alertasVentas,
                                  a.telefono,
                                  a.colaboradores,
                                  a.area,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.idPaqueteria,
                                  a.RFC
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>

                            <Button
                              color="warning"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarPedidoQR(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.codigo,
                                  a.idArea,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a.idColaboradores,
                                  a.alertasVentas,
                                  a.telefono,
                                  a.colaboradores,
                                  a.area,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.idPaqueteria,
                                  a.RFC
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                            </>
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_PEDIDOS}
                              hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                            />
                            {"  "}Modificado
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>{a.numero}</td>
                          <td>{a.fechaModificado}</td>
                          <td>{a.clientes}</td>
                          <td>{a.colaboradores}</td>
                          <td>{a.area}</td>
                          <td>
                            {user.surtidos_create ? (
                              <div>
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) =>
                                    PDFOC(
                                      a.fecha,
                                      a.colaboradores,
                                      a.idClientes,
                                      a.clientes,
                                      a.codigo,
                                      a.total_cantidad,
                                      a.total_general,
                                      a.observaciones,
                                      a.numero,
                                      a.idArea,
                                      a._id,
                                      a.alias,
                                      a.calle,
                                      a.numero_ext,
                                      a.numero_int,
                                      a.pais,
                                      a.estado,
                                      a.ciudad,
                                      a.delegacion,
                                      a.colonia,
                                      a.cp,
                                      a.RFC,
                                      a.paqueteria,
                                      a.area,
                                      a.telefono
                                    )
                                  }
                                >
                                  <i class="far fa-file-pdf"></i>
                                </Button>

                                <Button
                                  size="sm"
                                  className="btn"
                                  color="primary"
                                  onClick={(e) =>
                                    excelOC(a.idArea, a._id, a.clientes)
                                  }
                                >
                                  <i class="far fa-file-excel"></i>
                                </Button>
                              </div>
                            ) : undefined}
                          </td>
                          {a.surtido == "No" ? (                         <td>
                            <>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarPedido(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.codigo,
                                  a.idArea,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a.idColaboradores,
                                  a.alertasVentas,
                                  a.telefono,
                                  a.colaboradores,
                                  a.area,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.idPaqueteria,
                                  a.RFC
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>

                            <Button
                              color="warning"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                seleccionarPedidoQR(
                                  a._id,
                                  a.numero,
                                  a.idClientes,
                                  a.clientes,
                                  a.codigo,
                                  a.idArea,
                                  a.impuestos,
                                  a.comisionFlete,
                                  a.comisionTDC,
                                  a.idColaboradores,
                                  a.alertasVentas,
                                  a.telefono,
                                  a.colaboradores,
                                  a.area,
                                  a.alias,
                                  a.calle,
                                  a.numero_ext,
                                  a.numero_int,
                                  a.estado,
                                  a.ciudad,
                                  a.delegacion,
                                  a.colonia,
                                  a.cp,
                                  a.idPaqueteria,
                                  a.RFC
                                )
                              }
                            >
                              <i class="fas fa-shipping-fast"></i>
                            </Button>
                            </>
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td>
                            <StatusMultipleSurtido
                              idStatus={a._id}
                              status={a.surtido}
                              URL_STATUS={URL_PEDIDOS}
                              hijo={`${URL_ARTICULOS_PEDIDO}/baja`}
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          ) : abrirSurtirQR ? (
            
            // Termina Abrir Surtir
            <div className="card container col-md-12">
              <h4>Surtir Pedido QR {numero_pedido}</h4>
              <h4>
                Cliente: {clientesCodigo} {clientesName}
              </h4>

              <Form onSubmit={saveSurtidosQR}>
                <Row>
                  <Col md={2}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      placeholder="Impuestos"
                      value={impuestos}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Paqueteria</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedPaqueteria}
                      required
                      onChange={(e) => {
                        setSelectedPaqueteria(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona </option>
                      {paqueterias
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                </Row>

                <br />
                {/* Tabla Articulos QR */}

                <Row>
                  <Col md={1}>
                    <Label className="mr-sm-2">Codigo QR</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Modelo</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cant Pedido</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pend Surtir</Label>
                  </Col>
                  {/* {selectedArea == AREAS_CEDIS ? ( */}
                    <Col md={1}>
                      <Label className="mr-sm-2">Lote QR</Label>
                    </Col>
                  {/* ) : undefined} */}

                  <Col md={1}>
                    <Label className="mr-sm-2">Inventario</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pzas Caja</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                </Row>

                {inputFields2.map((inputField) => (
                  <div key={inputField.id}>

                    <Row
                    style={
                      inputField.cantInv < inputField.cantidad
                        ? { backgroundColor: "#ed8c8c" }
                        : undefined
                    }
                    >
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="string"
                          value={inputField.articulos}
                          placeholder=""
                          onChange={(event) => {
                            BuscaCodigo(inputField.id, event);
                          }}
                        ></Input>
                      </Col>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputField.articuloNombre}
                          disabled
                          // required
                          // onChange={(event) => {
                          //   BuscaArticulo(inputField.id, event);
                          // }}
                        >
                          {/* <option value="">Selecciona un Articulo</option> */}
                          {/* {articulosPed
                            .sort((a, b) =>
                              a.articuloNombre > b.articuloNombre ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a.articulos}>
                                  {a.articuloNombre}
                                </option>
                              );
                            })} */}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={inputField.precio}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantPed"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cant Ped"
                          value={inputField.cantPed}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="pendSurtir"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.pendSurtir}
                          required
                          disabled
                        />
                      </Col>

                      {/* {selectedArea == AREAS_CEDIS ? ( */}
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="lote"
                            type="select"
                            value={inputField.idInv}
                            required
                            onChange={(event) => {
                              BuscaLoteQR(inputField.id, event.target.value);
                            }}
                          >
                            <option value="">Selecciona</option>
                            {inventarios
                              .sort((a, b) => (a.lote > b.lote ? 1 : -1))
                              .map((a) => {
                                if (
                                  a.idArticulo == inputField.articulos &&
                                  a.idArea == selectedArea &&
                                  a.cantidad > 0
                                ) {
                                  return (
                                    <option value={a._id}>{a.lote}</option>
                                  );
                                }
                              })
                              .filter(function (el) {
                                return el != null;
                              })}
                          </Input>
                        </Col>
                      {/* ) : undefined} */}

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantInv"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantInv}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cajas"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cajas"
                          value={inputField.cajas}
                          onChange={(event) =>
                            handleChangeInputCantidadCajas(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="piezasPorCaja"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Piezas por Caja"
                          value={inputField.piezasPorCaja}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields2(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields2}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">
                      TOTALES {totalCajas} Cajas {total} Pzas.
                    </h4>
                  </Col>
                </Row>
                {/* Termina Tabla Articulos */}

                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirSurtirQR(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          ) :(
            // Termina Abrir Surtir
            <div className="card container col-md-12">
              <h4>Surtir Pedido {numero_pedido}</h4>
              <h4>
                Cliente: {clientesCodigo} {clientesName}
              </h4>

              <Form onSubmit={saveSurtidos}>
                <Row>
                  <Col md={2}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      placeholder="Impuestos"
                      value={impuestos}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Paqueteria</Label>
                    <Input
                      className="col-sm-12"
                      type="select"
                      value={selectedPaqueteria}
                      required
                      onChange={(e) => {
                        setSelectedPaqueteria(e.target.value);
                      }}
                    >
                      <option value="0">Selecciona </option>
                      {paqueterias
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </Col>
                </Row>

                <br />
                {/* Tabla Articulos */}

                <Row>
                  <Col md={1}>
                    <Label className="mr-sm-2">Codigo QR</Label>
                  </Col>
                  <Col md={2}>
                    <Label className="mr-sm-2">Modelo</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cant Pedido</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pend Surtir</Label>
                  </Col>
                  {/* {selectedArea == AREAS_CEDIS ? ( */}
                    <Col md={1}>
                      <Label className="mr-sm-2">Lote</Label>
                    </Col>
                  {/* ) : undefined} */}

                  <Col md={1}>
                    <Label className="mr-sm-2">Inventario</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pzas Caja</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                </Row>

                {inputFields.map((inputField) => (
                  <div key={inputField.id}>

                    <Row
                    style={
                      inputField.cantInv < inputField.cantidad
                        ? { backgroundColor: "#ed8c8c" }
                        : undefined
                    }
                    >
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="string"
                          value={inputField.articulos}
                          placeholder="Precio"
                          disabled
                          // onChange={(event) => {
                          //   BuscaCodigo(inputField.id, event);
                          // }}
                        ></Input>
                      </Col>
                      <Col md={2}>
                        <Input
                          bsSize="sm"
                          name="articulos"
                          type="text"
                          value={inputField.articuloNombre}
                          disabled
                          // required
                          // onChange={(event) => {
                          //   BuscaArticulo(inputField.id, event);
                          // }}
                        >
                          {/* <option value="">Selecciona un Articulo</option> */}
                          {/* {articulosPed
                            .sort((a, b) =>
                              a.articuloNombre > b.articuloNombre ? 1 : -1
                            )
                            .map((a) => {
                              return (
                                <option value={a.articulos}>
                                  {a.articuloNombre}
                                </option>
                              );
                            })} */}
                        </Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="precio"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Precio"
                          value={inputField.precio}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantPed"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cant Ped"
                          value={inputField.cantPed}
                          disabled
                        />
                      </Col>
                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="pendSurtir"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.pendSurtir}
                          required
                          disabled
                        />
                      </Col>

                      {/* {selectedArea == AREAS_CEDIS ? ( */}
                        <Col md={1}>
                          <Input
                            bsSize="sm"
                            name="lote"
                            type="select"
                            value={inputField.idInv}
                            required
                            onChange={(event) => {
                              BuscaLote(inputField.id, event.target.value);
                            }}
                          >
                            <option value="">Selecciona</option>
                            {inventarios
                              .sort((a, b) => (a.lote > b.lote ? 1 : -1))
                              .map((a) => {
                                if (
                                  a.idArticulo == inputField.articulos &&
                                  a.idArea == selectedArea &&
                                  a.cantidad > 0
                                ) {
                                  return (
                                    <option value={a._id}>{a.lote}</option>
                                  );
                                }
                              })
                              .filter(function (el) {
                                return el != null;
                              })}
                          </Input>
                        </Col>
                      {/* ) : undefined} */}

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantInv"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantInv}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cajas"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cajas"
                          value={inputField.cajas}
                          onChange={(event) =>
                            handleChangeInputCantidadCajas(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="piezasPorCaja"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Piezas por Caja"
                          value={inputField.piezasPorCaja}
                          required
                          disabled
                        />
                      </Col>

                      <Col md={1}>
                        <Input
                          bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>

                      <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col md={10}>
                    <h4 id="logoutBoton">
                      TOTALES {totalCajas} Cajas {total} Pzas.
                    </h4>
                  </Col>
                </Row>
                {/* Termina Tabla Articulos */}

                <br />
                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirSurtir(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoPendienteSurtir;
